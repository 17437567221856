import React, { memo, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import { AppSettings } from '@/shared/app-common';

import styles from './index.module.less';
import { useRouter } from 'next/router';

const Card = ({
  title,
  desc,
  imgPath,
  link,
  isRight,
}: {
  title: string;
  desc: string;
  imgPath: string;
  link: string;
  isRight?: boolean;
}) => {
  const router = useRouter();
  return (
    <div
      onClick={() => router.push(link)}
      className={styles.card}
      style={{ backgroundImage: `url(${imgPath})` }}
    >
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
      <p
        className={styles.desc}
        style={{ width: isRight ? '60%' : '45%' }}
        dangerouslySetInnerHTML={{ __html: desc }}
      />
    </div>
  );
};

const MemoizedCard = memo(Card);

const DetailShowContent = () => {
  const { t } = useTranslation('common');

  const items = [
    {
      title: t("Keep tabs on your loved ones' Instagram activities"),
      desc: t(
        "Dealing with trust 'issues'? Skip the phone snooping. Track Instagram activity discreetly and effortlessly.",
      ),
      imgPath: '/images/home/show-1.webp',
      link: `${AppSettings.blogHost}/instagram-tracking-blog/14993/how-can-i-see-my-girlfriends-activity-on-instagram`,
    },
    {
      title: t("Monitor your children's online interactions"),
      desc: t('MonitoringChildSafety_Desc'),
      imgPath: '/images/home/show-2.webp',
      link: `${AppSettings.blogHost}/instagram-tracking-blog/18595/moms-teach-teens-an-important-lesson-ensuring-online-safety`,
    },
    {
      title: t("Track potential clients' interests and activities"),
      desc: t(
        "By tracking potential and existing clients' latest posts, follows, and tags of interest, you can know more about your clients. This helps you win the business competition easily.",
      ),
      imgPath: '/images/home/show-6.webp',
      link: `${AppSettings.blogHost}/instagram-tracking-blog/18550/the-best-instagram-tool-for-finding-your-competitors-potential-customers-in-2024`,
    },
    {
      title: t('Gift-GivingInsights'),
      desc: t('Gift-GivingInsights_Desc'),
      imgPath: '/images/home/show-3.webp',
      isRight: true,
      link: `${AppSettings.blogHost}/instagram-tracking-blog/18718/how-to-buy-my-girlfriend-the-perfect-gift`,
    },
    {
      title: t('BuildingADeeperConnectionWithYourFavoriteStars'),
      desc: t(
        'Observe their Instagram behaviors to discover their routines, interests, and interactions, helping you feel more in tune with their world.',
      ),
      imgPath: '/images/home/show-4.webp',
      isRight: true,
      link: `${AppSettings.blogHost}/instagram-tracking-blog/18555/how-to-track-top-uk-celebrities-instagram-activity-using-dolphin-radar`,
    },
    {
      title: t('Conduct Recruitment Background Checks'),
      desc: t(
        "IEffortlessly and discreetly uncover details not reflected in resumes, such as candidates' interests and recent activities, ensuring you find the best fit for your organization's culture and values.",
      ),
      imgPath: '/images/home/show-7.webp',
      isRight: true,
      link: `${AppSettings.blogHost}/instagram-tracking-blog/18409/how-to-use-instagram-tracking-tools-to-make-your-headhunting-recruitment-more-effective`,
    },
  ];

  const getContent = useMemo(() => {
    return (
      <div className={styles.content}>
        <h2>{t('WhyDoYouNeedDolphinRadar')}</h2>
        <div className={styles.text}>
          <p>{t('Are you curious about those special Instagram accounts on your watchlist?')}</p>
          <p>{t('WhyDoYouNeedDolphinRadar_Desc')}</p>
        </div>

        <div className={styles.bottomContainer}>
          <div className={styles.leftSubContainer}>
            {items.slice(0, 3).map((item, index) => (
              <MemoizedCard key={index} {...item} />
            ))}
          </div>

          <div className={styles.leftSubContainer}>
            {items.slice(3).map((item, index) => (
              <MemoizedCard key={index} {...item} />
            ))}
          </div>
        </div>
      </div>
    );
  }, [items]);

  return <>{getContent}</>;
};

export default DetailShowContent;
